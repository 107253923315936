import { axiosConfig } from './axios-config.js'

export const notify = {
  // 取得裝置通知設定
  get: (id, token) => axiosConfig.get(`/api/resident/${id}/notify`, token),

  // 使用模板通知
  // "notify_setting_id": 1
  useSharedNotify: (id, data, token) => axiosConfig.patch(`/api/resident/${id}/notify`, data, token),

  // 使用自動通知
  /*
    "has_line": true,
    "has_push": 0,
    "has_exclude": 0,
    "exclude_range": [],
    "has_silent_bed": true,
    "silent_bed_timeout": 600,
    "has_silent_notify": false,
    "silent_notify_timeout": 0,
    "notify_leave": true,
    "notify_leave_action": true,
    "notify_leave_timeout": true,
    "notify_leave_timeout_max": 1200,
    "notify_leave_site": false,
    "notify_leave_site_max": 1,
    "notify_lying": false,
    "notify_lying_max": 4500,
    "notify_restless": true,
    "notify_restless_max": 20,
    "notify_restless_duration": 600,
    "notify_respiration": true,
    "notify_respiration_min": 12,
    "notify_respiration_max": 30,
    "notify_respiration_duration": 600
  */
  useCustomNotify: (id, data, token) => axiosConfig.patch(`/api/resident/${id}/notify`, data, token)
}
