<template>
  <div class="device-resident">
    <HeaderMenu />
    <div class="container-fluid py-4">
      <div
        class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-between
        "
      >
        <h1 class="h5 fw-bold mb-3">{{ $t("__residentManagement") }}</h1>
        <div class="d-inline-flex flex-wrap">
          <button
            v-if="userInfo && userInfo.isMultiAgency === false"
            type="button"
            class="btn btn-primary me-3 mb-3 text-nowrap rounded-pill"
            @click="addModal"
          >
            {{ $t('__add')}}
          </button>
          <form @submit.prevent="searchResident">
            <div class="input-group rounded-pill mb-3">
              <input
                v-model="search"
                type="text"
                class="form-control rounded-pill"
                :placeholder="$t('__searchResidentName')"
                aria-label="search"
                aria-describedby="search"
              />
              <button
                type="submit"
                class="btn btn-link px-2 text-dark"
                id="search"
              >
                <i class="bi bi-search"></i>
              </button>
              <button
                v-if="filter"
                type="button"
                class="btn btn-primary px-3 ms-1 rounded-pill"
                id="clearSearch"
                @click="clearSearch"
              >
                {{ $t("__clearSearch") }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <b-table
            responsive
            striped
            borderless
            head-variant="light"
            :empty-text="$t('__notData')"
            :empty-filtered-text="$t('__notFindResident')"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(gender)="data">
              {{
                data.item.gender === 1
                  ? $t('__male')
                  : data.item.gender === 2
                  ? $t('__female')
                  : 'N/A'
              }}
            </template>
            <template v-slot:cell(notify_settings)="data">
              <div
                v-if="data.item.notify_setting"
                class="d-none d-sm-inline-block"
              >
                <span class="pe-3"
                  :style="{ 'opacity': data.item.notify_setting.has_push === 0 && data.item.notify_setting.has_line === 0 ? 0.4 : data.item.notify_setting.notify_lying ? 1 : 0.4 }"
                >
                  <img
                    class="align-bottom"
                    src="@/assets/images/LYB.svg"
                    :alt="$t('__LYBNotifyText')"
                  />
                  <span class="ps-1">{{ $t('__LYBNotifyText') }}</span>
                </span>
                <span class="pe-3"
                  :style="{ 'opacity': data.item.notify_setting.has_push === 0 && data.item.notify_setting.has_line === 0 ? 0.4 : data.item.notify_setting.notify_leave && (data.item.notify_setting.notify_leave_action || data.item.notify_setting.notify_leave_timeout) ? 1 :  0.4 }"
                >
                  <img
                    class="align-bottom"
                    src="@/assets/images/LVB.svg"
                    :alt="$t('__leaveTheBed')"
                  />
                  <span class="ps-1">{{ $t('__leaveTheBed') }}</span>
                </span>
                <span class="pe-3"
                  :style="{ 'opacity': data.item.notify_setting.has_push === 0 && data.item.notify_setting.has_line === 0 ? 0.4 : data.item.notify_setting.notify_restless ? 1 :  0.4 }"
                >
                  <img
                    class="align-bottom pb-2"
                    src="@/assets/images/restless.svg"
                    :alt="$t('__restless')"
                  />
                  <span class="ps-1">{{ $t('__restless') }}</span>
                </span>
                <span class="pe-3"
                  :style="{ 'opacity': data.item.notify_setting.has_push === 0 && data.item.notify_setting.has_line === 0 ? 0.4 : data.item.notify_setting.notify_respiration ? 1 :  0.4 }"
                >
                  <img
                    class="align-bottom pb-2"
                    src="@/assets/images/rr.svg"
                    :alt="$t('__restless')"
                  />
                  <span class="ps-1">{{ $t('__RR') }}</span>
                </span>
                <span v-if="data.item.device && (data.item.device.chiline_user !== null || data.item.device.heart_rate_enabled === true)" class="pe-3"
                      :style="{ 'opacity': data.item.notify_setting.has_push === 0 && data.item.notify_setting.has_line === 0 ? 0.4 : data.item.notify_setting.notify_heartRate ? 1 :  0.4 }"
                >
                  <img
                    class="align-bottom pb-2"
                    style="width: 20px;"
                    src="@/assets/images/heart_rate.png"
                    :alt="$t('__heartRate')"
                  />
                  <span class="ps-1">{{ $t('__heartRate') }}</span>
                </span>
                <span v-if="data.item.device && data.item.device.chiline_user !== null" class="pe-3"
                      :style="{ 'opacity': data.item.notify_setting.has_push === 0 && data.item.notify_setting.has_line === 0 ? 0.4 : data.item.notify_setting.notify_spo2 ? 1 :  0.4 }"
                >
                  <img
                    class="align-bottom pb-2"
                    style="height: 25px;"
                    src="@/assets/images/spo2.png"
                    :alt="$t('__spo2')"
                  />
                  <span class="ps-1">{{ $t('__spo2') }}</span>
                </span>
                <span v-if="data.item.device && data.item.device.chiline_user !== null" class="pe-3"
                      :style="{ 'opacity': data.item.notify_setting.has_push === 0 && data.item.notify_setting.has_line === 0 ? 0.4 : data.item.notify_setting.notify_temp ? 1 :  0.4 }"
                >
                  <img
                    class="align-bottom pb-2"
                    style="height: 25px;"
                    src="@/assets/images/temp.png"
                    :alt="$t('__temp')"
                  />
                  <span class="ps-1">{{ $t('__temp') }}</span>
                </span>
              </div>
            </template>
            <template v-slot:cell(active)="data">
              <div class="d-flex">
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="data.item.hasEditPermission ? $t('__notifySettings') : $t('__noPermission')"
                  class="d-inline-block"
                >
                  <button
                    @click="editModal(data.item.id)"
                    :disabled="!data.item.hasEditPermission"
                    type="button"
                    class="btn btn-link px-1 text-dark"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('__edit')"
                  >
                    <i class="bi bi-pencil mx-1"></i>
                  </button>
                </span>
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="data.item.hasEditPermission ? $t('__notifySettings') : $t('__noPermission')"
                  class="d-inline-block"
                >
                  <button
                    @click="showNotifyModal(data.item.id, data.item.device)"
                    :disabled="!data.item.hasEditPermission"
                    type="button"
                    class="btn btn-link px-1 text-dark"
                  >
                    <i class="bi bi-bell mx-1"></i>
                  </button>
                </span>
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="data.item.hasEditPermission ? $t('__delete') : $t('__noPermission')"
                  class="d-inline-block"
                >
                  <button
                    v-if="!data.item.device && !data.item.length"
                    :disabled="!data.item.hasEditPermission"
                    type="button"
                    href=""
                    class="btn btn-link px-1 text-danger"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('__delete')"
                    @click="deleteAlert(data.item.id)"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </span>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="totalRows">
        <div class="col-sm col-12 text-right text-sm-left">
          {{ $t('__paginationInfo', {
            startNub: currentPage == 1 ? 1 : perPage * (currentPage - 1) + 1,
            entNub: currentPage == 1
              ? totalRows > perPage
                ? perPage
                : totalRows
              : perPage * (currentPage - 1) + perPage > totalRows
              ? totalRows
              : perPage * (currentPage - 1) + perPage ,
            sum: totalRows
            }) }}
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-sm-0 mb-3 mt-sm-0 mt-3 cms_main"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div class="modal fade cms-style" tabindex="-1" id="modal" ref="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitResidentForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium">
                {{ $t("__residentManagementSettings") }}
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="resident_name" class="form-label fw-medium">{{
                      $t('__residentName')
                    }}</label>
                    <input
                      v-model="editResidentForm.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.name
                      }"
                      id="resident_name"
                      required
                    />
                    <div
                      class="invalid-feedback"
                      v-if="validate && validate.msg.name"
                    >
                      <div
                        v-for="(msg, index) in validate.msg.name"
                        :key="'resident_name' + index"
                      >
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="form-label fw-medium">{{
                      $t('__residentGender')
                    }}</label>
                    <div>
                      <div class="form-check form-check-inline">
                        <input
                          v-model="editResidentForm.gender"
                          class="form-check-input"
                          :class="{
                            'is-invalid':
                              validate && validate.msg.gender
                          }"
                          type="radio"
                          name="gender"
                          id="resident_gender_male"
                          value="1"
                          required
                        />
                        <label
                          class="form-check-label"
                          for="resident_gender_male"
                          >{{ $t('__male') }}</label
                        >
                        <div
                          class="invalid-feedback"
                          v-if="validate && validate.msg.gender"
                        >
                          <div
                            v-for="(msg, index) in validate.msg.gender"
                            :key="'gender' + index"
                          >
                            {{ msg }}
                          </div>
                        </div>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          v-model="editResidentForm.gender"
                          class="form-check-input"
                          :class="{
                            'is-invalid':
                              validate && validate.msg.gender
                          }"
                          type="radio"
                          name="gender"
                          id="resident_gender_female"
                          value="2"
                          required
                        />
                        <label
                          class="form-check-label"
                          for="resident_gender_female"
                          >{{ $t('__female') }}</label
                        >
                        <div
                          class="invalid-feedback"
                          v-if="validate && validate.msg.gender"
                        >
                          <div
                            v-for="(msg, index) in validate.msg.gender"
                            :key="'gender' + index"
                          >
                            {{ msg }}
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="resident_birthday" class="form-label fw-medium">{{
                      $t('__residentBirthday')
                    }}</label>
                    <input
                      v-model="editResidentForm.birthday"
                      type="date"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.birthday
                      }"
                      id="resident_birthday"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="validate && validate.msg.birthday"
                    >
                      <div
                        v-for="(msg, index) in validate.msg.birthday"
                        :key="'resident_birthday' + index"
                      >
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="idNumber" class="form-label fw-medium">{{
                      $t('__idNumber')
                    }}</label>
                    <input
                      v-model="editResidentForm.id_number"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.id_number
                      }"
                      id="idNumber"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="validate && validate.msg.id_number"
                    >
                      <div
                        v-for="(msg, index) in validate.msg.id_number"
                        :key="'id_number' + index"
                      >
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="note" class="form-label fw-medium">{{
                      $t('__note')
                    }}</label>
                    <textarea
                      v-model="editResidentForm.note"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.note
                      }"
                      id="note"
                      rows="3"
                    ></textarea>
                    <div
                      class="invalid-feedback"
                      v-if="validate && validate.msg.note"
                    >
                      <div
                        v-for="(msg, index) in validate.msg.note"
                        :key="'note' + index"
                      >
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t("__cancel") }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t("__save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="deleteModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="deleteModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__deleteResident") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">{{ $t("__deleteResidentInfo") }}</p>
            <p class="mb-0 fs-5 py-2 fw-medium">【{{ editResidentForm.name }}】</p>
            <p class="mb-0">{{ $t("__resident") }}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="deleteResident(edit_id)"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="notify"
      ref="notifyModal"
    >
      <div v-if="notifyModalData" class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-5">
            <div class="h4 modal-title fw-medium text-center">
              <span class="fs-6 d-block">
                {{ modalTitle.name }}
              </span>
              {{ $t("__notifySettings") }}
            </div>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="h5 fw-medium mb-3">
              {{ $t('__notifySettings') }}
            </div>
            <div v-if="!hasnotifyData" class="text-danger mb-3">
              {{ $t('__notifyIsNotSet') }}
            </div>
            <div class="custom-tab-check d-flex">
              <!-- <div class="form-check">
                <input
                  v-model="notifySettingType"
                  class="form-check-input"
                  type="radio"
                  name="notifySettingType"
                  value="template"
                  id="notifySettingTypeTemplate"
                />
                <label
                  class="form-check-label fw-medium"
                  for="notifySettingTypeTemplate"
                >
                  {{ $t('__template') }}
                </label>
              </div> -->
              <div class="form-check">
                <input
                  v-model="notifySettingType"
                  class="form-check-input"
                  type="radio"
                  name="notifySettingType"
                  value="custom"
                  id="notifySettingTypeCustom"
                />
                <label
                  class="form-check-label fw-medium"
                  for="notifySettingTypeCustom"
                >
                  {{ $t('__custom') }}
                </label>
              </div>
            </div>
            <div class="p-3 bg-white" v-if="notifySettingType === 'custom'">
              <div class="row align-items-center mb-3">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__notifyMethod') }}
                </div>
                <div class="col-lg-9">
                  <div class="form-check form-check-inline">
                    <input
                      v-model="notifyModalData.has_line"
                      class="form-check-input"
                      type="checkbox"
                      id="has_line"
                    />
                    <label class="form-check-label" for="has_line">LINE</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="notifyModalData.has_push"
                      class="form-check-input"
                      type="checkbox"
                      id="has_push"
                    />
                    <label class="form-check-label" for="has_push">{{
                      $t('__push')
                    }}</label>
                  </div>
                  <div v-if="notifyModalData.has_line">
                    <small
                      class="d-block text-danger"
                      v-if="LINEAuth && LINEAuth.length"
                      >{{
                        $t('__LINENotifyAuthorizedQuantity', {
                          qty: LINEAuth.length
                        })
                      }}</small
                    >
                    <small class="d-block text-danger" v-else>{{
                      $t('__LINENotifyAuthorizeNoAccount')
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row align-items-top mb-3">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__excludeRange') }}
                </div>
                <div class="col-lg-9">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="has_exclude"
                      v-model="notifyModalData.has_exclude"
                    />
                    <label class="form-check-label" for="has_exclude">{{
                      $t('__enable')
                    }}</label>
                  </div>
                  <small
                    class="text-danger d-block mt-1"
                    v-if="notifyModalData.has_exclude"
                    >{{ $t('__excludeRangeNote') }}</small
                  >
                  <div v-if="notifyModalData.has_exclude" class="pt-3">
                    <div
                      v-for="(excludeTime,
                      index) in notifyModalData.exclude_range"
                      :key="'excludeTime' + index"
                      class="row p-3 g-1 align-items-center"
                      :class="{ 'bg-light': index % 2 === 0 }"
                    >
                      <div class="col d-flex align-items-center mt-2 mt-lg-0">
                        <VueCtkDateTimePicker
                          :label="$t('__startTime')"
                          v-model="excludeTime.started_at"
                          only-time
                          format="HH:mm"
                          formatted="HH:mm"
                          right
                          color="#2CBDC0"
                          button-color="#2CBDC0"
                          :id="'excludeTimeStart_at' + index"
                        />
                        <span class="px-1">-</span>
                        <VueCtkDateTimePicker
                          :label="$t('__entTime')"
                          v-model="excludeTime.ended_at"
                          only-time
                          format="HH:mm"
                          formatted="HH:mm"
                          right
                          color="#2CBDC0"
                          button-color="#2CBDC0"
                          :id="'excludeTimeEnd_at' + index"
                        />
                      </div>
                      <div class="col-auto mt-2 mt-lg-0">
                        <button
                          type="button"
                          class="btn-close"
                          @click="delExcludeTime(index)"
                        ></button>
                      </div>
                    </div>
                  </div>
                  <div class="text-end mt-2">
                    <button
                      v-if="
                        notifySettingType === 'custom' &&
                          notifyModalData.exclude_range.length < 5 &&
                          notifyModalData.has_exclude
                      "
                      class="btn btn-outline-primary rounded-pill"
                      @click="addExcludeTime"
                    >
                      {{ $t('__addExcludeTime') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="row align-items-top mb-3">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__otherConditions') }}
                </div>
                <div class="col-lg-9">
                  <div
                    class="
                      form-check
                      d-flex
                      align-items-top align-items-sm-center
                      mb-3
                    "
                  >
                    <input
                      class="form-check-input me-2"
                      type="checkbox"
                      id="has_silent_notify"
                      v-model="notifyModalData.has_silent_notify"
                    />
                    <label
                      class="form-check-label align-items-center"
                      for="has_silent_notify"
                    >
                      {{ $t('__afterNotification') }}
                      <span class="d-block d-sm-inline-block">
                        <input
                          :value="
                            secondsToMinutes(
                              notifyModalData.silent_notify_timeout
                            )
                          "
                          @change="
                            notifyModalData.silent_notify_timeout = minutesToSeconds(
                              $event.target.value
                            )
                          "
                          type="number"
                          class="
                            form-control
                            d-inline-block
                            mx-sm-2
                            me-2
                            mt-sm-0 mt-2
                          "
                          style="width: 100px"
                        />
                        {{ $t('__minutesLaterStartAgain') }}
                      </span>
                    </label>
                  </div>
                  <div
                    class="
                      form-check
                      d-flex
                      align-items-top align-items-sm-center
                      mb-3
                    "
                  >
                    <input
                      class="form-check-input me-2"
                      type="checkbox"
                      id="has_silent_bed"
                      v-model="notifyModalData.has_silent_bed"
                    />
                    <label
                      class="form-check-label align-items-center"
                      for="has_silent_bed"
                    >
                      {{ $t('__backToBed') }}
                      <span class="d-block d-sm-inline-block">
                        <input
                          :value="
                            secondsToMinutes(notifyModalData.silent_bed_timeout)
                          "
                          @change="
                            notifyModalData.silent_bed_timeout = minutesToSeconds(
                              $event.target.value
                            )
                          "
                          type="number"
                          class="
                            form-control
                            d-inline-block
                            mx-sm-2
                            me-2
                            mt-sm-0 mt-2
                          "
                          style="width: 100px"
                        />
                        {{ $t('__withinMinutesWithoutNotification') }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                class="
                  d-flex
                  flex-column flex-md-row
                  cms-style
                  side-tab
                "
              >
                <div class="left-col">
                  <ul
                    class="list-group flex-md-column flex-row"
                    ref="customList"
                  >
                    <li class="list-group-item">
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#LVBStatistics"
                        type="button"
                        role="tab"
                        aria-controls="LVBStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__LVBNotify') }}
                      </button>
                    </li>
                    <li class="list-group-item">
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#LYBStatistics"
                        type="button"
                        role="tab"
                        aria-controls="LYBStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__LYBNotify') }}
                      </button>
                    </li>
                    <li class="list-group-item">
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#RRNotifyStatistics"
                        type="button"
                        role="tab"
                        aria-controls="RRNotifyStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__RRNotify') }}
                      </button>
                    </li>
                    <li class="list-group-item">
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#restlessNotifyStatistics"
                        type="button"
                        role="tab"
                        aria-controls="restlessNotifyStatistics"
                        aria-selected="true"
                      >
                        {{ $t("__restlessNotify") }}
                      </button>
                    </li>
                    <li class="list-group-item" v-if="notifyModalData.isChilineUser === true || notifyModalData.heartRateEnabled === true">
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#heartRateNotifyStatistics"
                        type="button"
                        role="tab"
                        aria-controls="heartRateNotifyStatistics"
                        aria-selected="true"
                      >
                        {{ $t("__heartRateNotify") }}
                      </button>
                    </li>
                    <li class="list-group-item" v-if="notifyModalData.isChilineUser === true">
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#spo2NotifyStatistics"
                        type="button"
                        role="tab"
                        aria-controls="spo2NotifyStatistics"
                        aria-selected="true"
                      >
                        {{ $t("__spo2Notify") }}
                      </button>
                    </li>
                    <li class="list-group-item" v-if="notifyModalData.isChilineUser === true">
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#tempNotifyStatistics"
                        type="button"
                        role="tab"
                        aria-controls="tempNotifyStatistics"
                        aria-selected="true"
                      >
                        {{ $t("__tempNotify") }}
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="right-col flex-fill">
                  <div class="tab-content cms-style h-100">
                    <div
                      class="tab-pane fade h-100"
                      id="LVBStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__status') }}
                          </div>
                          <div class="col-md-9">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_leave"
                                @click="clickNotifyLeave"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_leave"
                              />
                              <label
                                class="form-check-label"
                                for="notify_leave"
                              >
                                {{ $t('__enable') }}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="notifyModalData.notify_leave">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_leave_action"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_leave_action"
                              />
                              <label
                                class="form-check-label"
                                for="notify_leave_action"
                              >
                                {{ $t('__leaveTheBed') }}
                              </label>
                            </div>
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_leave_timeout"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_leave_timeout"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="notify_leave_timeout"
                              >
                                {{ $t('__leaveTheBedTimeOut') }}
                              </label>
                            </div>
                            <div class="pt-4 mb-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_leave_timeout_max
                                "
                                :min="60"
                                :max="18000"
                                :format="SliderFormatMinuteMore"
                                :step="60"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade h-100"
                      id="LYBStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__status') }}
                          </div>
                          <div class="col-md-9">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_lying"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_lying"
                              />
                              <label
                                class="form-check-label"
                                for="notify_lying"
                              >
                                {{ $t('__enable') }}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="notifyModalData.notify_lying">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <label class="form-check-label" for="">
                              {{ $t('__LYBTime') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_lying_max
                                "
                                :min="3600"
                                :max="21600"
                                :format="SliderFormatMinuteMore"
                                :step="60"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade h-100"
                      id="RRNotifyStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__status') }}
                          </div>
                          <div class="col-md-5">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_respiration"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_respiration"
                              />
                              <label
                                class="form-check-label"
                                for="notify_respiration"
                              >
                                {{ $t('__enable') }}
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <label
                              v-if=notifyModalData.suggest_rr
                              class="form-check-label"
                            >
                              {{ $t('__rrValueSuggest') + ':' + notifyModalData.suggest_rr.min + '~' + notifyModalData.suggest_rr.max }}
                            </label>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="notifyModalData.notify_respiration"
                        >
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <label class="form-check-label">
                              {{ $t('__rrValue') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                :value="sliderNotifyRespiration"
                                @update="changeSliderNotifyRespiration"
                                :min="1"
                                :max="45"
                              />
                            </div>
                            <label class="form-check-label mt-3">
                              {{ $t('__lastTime') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_respiration_duration
                                "
                                :min="60"
                                :max="7200"
                                :format="SliderFormatMinute"
                                :step="60"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade h-100"
                      id="restlessNotifyStatistics"
                      role="tabpanel"
                      aria-labelledby="restlessNotifyStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t("__status") }}
                          </div>
                          <div class="col-md-9">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_restless"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_restless"
                              />
                              <label
                                class="form-check-label"
                                for="notify_restless"
                              >
                                {{ $t("__enable") }}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="notifyModalData.notify_restless">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t("__notifyConditions") }}
                          </div>
                          <div class="col-md-9">
                            <label class="form-check-label">
                              {{ $t("__restlessnessValue") }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_restless_max
                                "
                                :min="0"
                                :max="100"
                                :format="SliderFormatMore"
                              />
                            </div>
                            <label class="form-check-label mt-3">
                              {{ $t("__lastTime") }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_restless_duration
                                "
                                :min="60"
                                :max="600"
                                :format="SliderFormatMinute"
                                :step="60"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade h-100"
                      id="heartRateNotifyStatistics"
                      role="tabpanel"
                      aria-labelledby="heartRateNotifyStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__status') }}
                          </div>
                          <div class="col-md-5">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_heartRate"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_respiration"
                              />
                              <label
                                class="form-check-label"
                                for="notify_respiration"
                              >
                                {{ $t('__enable') }}
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <label
                              v-if=notifyModalData.suggest_heartRate
                              class="form-check-label"
                            >
                              {{ $t('__heartRateValueSuggest') + ':' + notifyModalData.suggest_heartRate.min + '~' + notifyModalData.suggest_heartRate.max }}
                            </label>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="notifyModalData.notify_heartRate"
                        >
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <label class="form-check-label">
                              {{ $t('__heartRateValue') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                :value="sliderNotifyHeartRate"
                                @update="changeSliderNotifyHeartRate"
                                :min="20"
                                :max="200"
                              />
                            </div>
                            <label class="form-check-label mt-3">
                              {{ $t('__lastTime') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_heartRate_duration
                                "
                                :min="60"
                                :max="12000"
                                :format="SliderFormatMinute"
                                :step="60"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade h-100"
                      id="spo2NotifyStatistics"
                      role="tabpanel"
                      aria-labelledby="spo2NotifyStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__status') }}
                          </div>
                          <div class="col-md-5">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_spo2"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_respiration"
                              />
                              <label
                                class="form-check-label"
                                for="notify_respiration"
                              >
                                {{ $t('__enable') }}
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <label
                              v-if=notifyModalData.suggest_spo2
                              class="form-check-label"
                            >
                              {{ $t('__spo2ValueSuggest') + ':' + notifyModalData.suggest_spo2.min + '~' + notifyModalData.suggest_spo2.max }}
                            </label>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="notifyModalData.notify_spo2"
                        >
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <label class="form-check-label">
                              {{ $t('__spo2Value') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_spo2_max
                                "
                                :min="50"
                                :max="100"
                                :format="SliderFormatLess"
                              />
                            </div>
                            <label class="form-check-label mt-3">
                              {{ $t('__lastTime') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_spo2_duration
                                "
                                :min="60"
                                :max="12000"
                                :format="SliderFormatMinute"
                                :step="60"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade h-100"
                      id="tempNotifyStatistics"
                      role="tabpanel"
                      aria-labelledby="tempNotifyStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__status') }}
                          </div>
                          <div class="col-md-5">
                            <div
                              class="form-check d-flex align-items-center mb-3"
                            >
                              <input
                                v-model="notifyModalData.notify_temp"
                                class="form-check-input me-2"
                                type="checkbox"
                                id="notify_respiration"
                              />
                              <label
                                class="form-check-label"
                                for="notify_respiration"
                              >
                                {{ $t('__enable') }}
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <label
                              v-if=notifyModalData.suggest_temp
                              class="form-check-label"
                            >
                              {{ $t('__tempValueSuggest') + ':' + notifyModalData.suggest_temp.min + '~' + notifyModalData.suggest_temp.max }}
                            </label>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="notifyModalData.notify_temp"
                        >
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <label class="form-check-label">
                              {{ $t('__tempValue') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                :value="sliderNotifyTemp"
                                @update="changeSliderNotifyTemp"
                                :min="25"
                                :max="50"
                              />
                            </div>
                            <label class="form-check-label mt-3">
                              {{ $t('__lastTime') }}
                            </label>
                            <div class="pt-4 mt-3">
                              <Slider
                                v-model.number="
                                  notifyModalData.notify_temp_duration
                                "
                                :min="60"
                                :max="12000"
                                :format="SliderFormatMinute"
                                :step="60"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3 bg-white" v-else>
              <div class="row mb-3">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__openThisSelectMenu') }}
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col">
                      <div class="input-group mb-2">
                        <select
                          class="form-select border-0"
                          v-model="notifySettingTypeID"
                        >
                          <option
                            v-for="item in notifySetting"
                            :key="item.id"
                            :value="item.id"
                          >
                            {{
                              item.is_template
                                ? $t('__' + item.name)
                                : item.name
                            }}
                          </option>
                        </select>
                        <button
                          class="btn btn-link p-2 text-dark"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="$t('__getTemplates')"
                          @click="getNotify"
                        >
                          <i class="bi bi-arrow-clockwise"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col-auto">
                      <button
                        class="btn btn-danger me-2 mb-2"
                        @click="toEditThisTemplate(notifySettingTypeID)"
                      >
                        {{ $t('__editThisTemplate') }}
                      </button>
                      <button
                        class="btn btn-outline-danger mb-2"
                        @click="copyNotifyData"
                      >
                        {{ $t('__copyThisTemplateToCustom') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__notifyMethod') }}
                </div>
                <div class="col-lg-9">
                  {{ notifyModalData.has_line ? 'LINE' : '' }}
                  <span
                    v-if="notifyModalData.has_line && notifyModalData.has_push"
                    >、</span
                  >
                  {{ notifyModalData.has_push ? $t('__push') : '' }}
                  <div v-if="notifyModalData.has_line">
                    <small
                      class="d-block text-danger"
                      v-if="LINEAuth && LINEAuth.length"
                      >{{
                        $t('__LINENotifyAuthorizedQuantity', {
                          qty: LINEAuth.length
                        })
                      }}</small
                    >
                    <small class="d-block text-danger" v-else>{{
                      $t('__LINENotifyAuthorizeNoAccount')
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row mb-3" v-if="notifyModalData.has_exclude">
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__excludeRange') }}
                </div>
                <div class="col-lg-9">
                  <small class="text-danger d-block mb-1">{{
                    $t('__excludeRangeNote')
                  }}</small>
                  <div
                    v-for="(excludeTime,
                    index) in notifyModalData.exclude_range"
                    :key="'excludeTime' + index"
                    class="p-3"
                    :class="{ 'bg-light': index % 2 === 0 }"
                  >
                    <span>{{
                      excludeTime.started_at + '-' + excludeTime.ended_at
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                class="row mb-3 align-items-center"
                v-if="
                  notifyModalData.has_silent_bed ||
                    notifyModalData.has_silent_notify
                "
              >
                <div class="col-lg-3 fw-medium mb-2 mb-lg-0">
                  {{ $t('__otherConditions') }}
                </div>
                <div class="col-lg-9">
                  <div v-if="notifyModalData.has_silent_notify">
                    {{
                      $t('__afterNotificationInfo', {
                        nub: secondsToMinutes(
                          notifyModalData.silent_notify_timeout
                        )
                      })
                    }}
                  </div>
                  <div v-if="notifyModalData.has_silent_bed">
                    {{
                      $t('__backToBedInfo', {
                        nub: secondsToMinutes(
                          notifyModalData.silent_bed_timeout
                        )
                      })
                    }}
                  </div>
                </div>
              </div>
              <div
                class="
                  d-flex
                  flex-column flex-md-row
                  cms-style
                  side-tab
                "
              >
                <div class="left-col">
                  <ul
                    class="list-group flex-md-column flex-row templateList"
                    ref="templateList"
                  >
                    <li
                      class="list-group-item"
                      v-if="notifyModalData.notify_leave"
                    >
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#LVBStatistics"
                        type="button"
                        role="tab"
                        aria-controls="LVBStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__LVBNotify') }}
                      </button>
                    </li>
                    <li
                      class="list-group-item"
                      v-if="notifyModalData.notify_lying"
                    >
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#LYBStatistics"
                        type="button"
                        role="tab"
                        aria-controls="LYBStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__LYBNotify') }}
                      </button>
                    </li>
                    <li
                      class="list-group-item"
                      v-if="notifyModalData.notify_respiration"
                    >
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#RRNotifyStatistics"
                        type="button"
                        role="tab"
                        aria-controls="RRNotifyStatistics"
                        aria-selected="true"
                      >
                        {{ $t('__RRNotify') }}
                      </button>
                    </li>
                    <li
                      class="list-group-item"
                      v-if="notifyModalData.notify_restless"
                    >
                      <button
                        class="btn btn-link nav-link fw-medium"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#restlessNotifyStatistics"
                        type="button"
                        role="tab"
                        aria-controls="restlessNotifyStatistics"
                        aria-selected="true"
                      >
                        {{ $t("__restlessNotify") }}
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="right-col flex-fill">
                  <div class="tab-content cms-style h-100">
                    <div
                      v-if="notifyModalData.notify_leave"
                      class="tab-pane fade h-100"
                      id="LVBStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <div v-if="notifyModalData.notify_leave_action">
                              {{ $t('__leaveTheBed') }}
                            </div>
                            <div v-if="notifyModalData.notify_leave_timeout">
                              {{
                                $t('__leaveTheBedTimeOut') +
                                  ' ' +
                                  secondsToMinutes(
                                    notifyModalData.notify_leave_timeout_max
                                  ) +
                                  ' ' +
                                  $t('__minute')
                              }}
                            </div>
                            <!-- <div v-if="notifyModalData.notify_leave_site">
                              {{
                                $t("__SIT") +
                                " " +
                                notifyModalData.notify_leave_site_max +
                                " " +
                                $("__second")
                              }}
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="notifyModalData.notify_lying"
                      class="tab-pane fade h-100"
                      id="LYBStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            {{
                              $t('__LYBTime') +
                                ' > ' +
                                secondsToMinutes(
                                  notifyModalData.notify_lying_max
                                ) +
                                ' ' +
                                $t('__minute')
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="notifyModalData.notify_respiration"
                      class="tab-pane fade h-100"
                      id="RRNotifyStatistics"
                      role="tabpanel"
                      aria-labelledby="LVBStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t('__notifyConditions') }}
                          </div>
                          <div class="col-md-9">
                            <div>
                              {{
                                $t('__rrValue') +
                                  ' &lt; ' +
                                  sliderNotifyRespiration[0] +
                                  '、 > ' +
                                  sliderNotifyRespiration[1]
                              }}
                            </div>
                            <div>
                              {{
                                $t('__lastTime') +
                                  ' ' +
                                  secondsToMinutes(
                                    notifyModalData.notify_respiration_duration
                                  ) +
                                  ' ' +
                                  $t('__minute')
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="notifyModalData.notify_restless"
                      class="tab-pane fade h-100"
                      id="restlessNotifyStatistics"
                      role="tabpanel"
                      aria-labelledby="restlessNotifyStatistics-tab"
                    >
                      <div class="px-3 px-md-5 py-3">
                        <div class="row">
                          <div class="col-md-3 fw-medium mb-2 mb-md-0">
                            {{ $t("__notifyConditions") }}
                          </div>
                          <div class="col-md-9">
                            <div>
                              {{
                                $t("__restlessnessValue") +
                                " > " +
                                notifyModalData.notify_restless_max
                              }}
                            </div>
                            <div>
                              {{
                                $t("__lastTime") +
                                " " +
                                secondsToMinutes(
                                  notifyModalData.notify_restless_duration
                                ) +
                                " " +
                                $t("__minute")
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t('__cancel') }}
            </button>
            <button
              type="button"
              class="btn btn-primary rounded-pill"
              @click="submitNotifyModal"
            >
              {{ $t('__save') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/HeaderMenu.vue'
import Slider from '@vueform/slider/dist/slider.vue2.js'
import '@vueform/slider/themes/default.css'
import { Tooltip, Modal, Tab } from 'bootstrap'
import i18n from '@/lang/lang.js'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import { resident } from '@/http/api/resident.js'
import { notify } from '@/http/api/notify.js'
import { notifyTemplate } from '@/http/api/notifyTemplate.js'
import { line } from '@/http/api/line.js'
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'ResidentManagement',
  components: { HeaderMenu, VueCtkDateTimePicker, Slider },
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: i18n.t('__residentName'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'gender',
          label: i18n.t('__residentGender'),
          class: 'align-middle'
        },
        {
          key: 'id_number',
          label: i18n.t('__idNumber'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'age',
          label: i18n.t('__residentAge'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'birthday',
          label: i18n.t('__residentBirthday'),
          class: 'align-middle'
        },
        {
          key: 'note',
          label: i18n.t('__note'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'notify_settings',
          label: i18n.t('__notifyMethod'),
          class: 'align-middle'
        },
        {
          key: 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle'
        }
      ],
      items: null,
      filter: null,
      filterOn: ['name'],
      search: null,
      bootstrap_tooltip: null,
      currentPage: 1,
      perPage: 10,
      is_edit: false,
      editResidentId: null,
      bootstrap_modal: null,
      editResidentForm: {
        name: null,
        birthday: '',
        gender: null,
        id_number: null,
        note: null
      },
      validate: null,
      hasnotifyData: false,
      notifyModalData: {
        exclude_range: [],
        has_line: 0,
        has_push: 0,
        has_exclude: 0,
        has_silent_bed: 0,
        silent_bed_timeout: 300,
        has_silent_notify: 0,
        silent_notify_timeout: 600,
        notify_leave: 0,
        notify_leave_action: 0,
        notify_leave_timeout: 0,
        notify_leave_timeout_max: 900,
        notify_leave_site: 0,
        notify_leave_site_max: 10,
        notify_lying: 0,
        notify_lying_max: 7800,
        notify_restless: 0,
        notify_restless_max: 45,
        notify_restless_duration: 300,
        notify_respiration: 0,
        notify_respiration_min: 12,
        notify_respiration_max: 28,
        notify_respiration_duration: 600,
        notify_heartRate: 0,
        notify_heartRate_min: 40,
        notify_heartRate_max: 110,
        notify_heartRate_duration: 900,
        notify_spo2: 0,
        notify_spo2_max: 94,
        notify_spo2_duration: 900,
        notify_temp: 0,
        notify_temp_min: 35,
        notify_temp_max: 39,
        notify_temp_duration: 1200
      },
      modalTitle: {
        id: null,
        name: null
      },
      showNotifyResidentID: null,
      notifySettingType: 'custom', // template
      notifySettingTypeID: 1,
      notifySetting: null,
      bootstrap_template_tab: [],
      bootstrap_custom_tab: [],
      LINEAuth: null,
      SliderFormatMore: val => {
        return i18n.t('__moreThanThe') + Math.round(val)
      },
      SliderFormatLess: val => {
        return i18n.t('__lessThan') + Math.round(val)
      },
      SliderFormatMinuteMore: val => {
        return (
          i18n.t('__moreThanThe') + Math.round(val / 60) + i18n.t('__minute')
        )
      },
      SliderFormatMinuteLess: val => {
        return i18n.t('__lessThan') + Math.round(val / 60) + i18n.t('__minute')
      },
      SliderFormatMinute: val => {
        return Math.round(val / 60) + i18n.t('__minute')
      },
      SliderFormatSecond: val => {
        return Math.round(val) + i18n.t('__second')
      },
      SliderFormatSecondLast: val => {
        return i18n.t('__last') + Math.round(val) + i18n.t('__second')
      }
    }
  },
  computed: {
    ...mapState(['token', 'userInfo', 'timezone']),
    ...mapGetters(['timeZoneOffset']),
    totalRows () {
      return this.items ? this.items.length : null
    },
    sliderNotifyRespiration () {
      if (this.notifyModalData) {
        return [
          this.notifyModalData.notify_respiration_min,
          this.notifyModalData.notify_respiration_max
        ]
      } else {
        return null
      }
    },
    sliderNotifyHeartRate () {
      if (this.notifyModalData) {
        return [
          this.notifyModalData.notify_heartRate_min,
          this.notifyModalData.notify_heartRate_max
        ]
      } else {
        return null
      }
    },
    sliderNotifyTemp () {
      if (this.notifyModalData) {
        return [
          this.notifyModalData.notify_temp_min,
          this.notifyModalData.notify_temp_max
        ]
      } else {
        return null
      }
    }
  },
  watch: {
    items () {
      this.initBootstrapTooltip()
    },
    item (data) {
      this.totalRows = data.length
    },
    userInfo () {
      this.getResidents()
      this.getNotify()
    },
    notifySettingTypeID () {
      this.getNotifySetting()
    },
    notifySettingType () {
      const vm = this
      vm.$nextTick(function () {
        vm.getNotifySetting()
        vm.initBootstrapTab()
        vm.initBootstrapTooltip()
      })
    },
    editResidentForm: {
      handler: function () {
        this.validate = null
      },
      deep: true
    },
    verifyClearRecord: {
      handler: function () {
        this.validate = null
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations([
      'LoadingText',
      'Loading',
      'Loaded',
      'AlertsInfo',
      'Alerted'
    ]),
    getResidents (id) {
      const vm = this
      vm.Loading()
      resident.get(id, vm.token).then((res) => {
        if (res.status <= 201) {
          if (id) {
            for (var i = 0; i < vm.items.length; i++) {
              if (vm.items[i].id === res.data.data.id) { // 如果是指定住民id則只取得該住民資料
                vm.items[i].notify_setting = res.data.data.notify_setting
                break
              }
            }
          } else {
            // 取得所有住民資料
            vm.items = res.data.data.map(resident => {
              const hasEditPermission = vm.userInfo.agencyPermission[resident.agency_id] && vm.userInfo.agencyPermission[resident.agency_id].edit
              return { ...resident, hasEditPermission }
            })
          }
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    searchResident () {
      this.filter = this.search
    },
    clearSearch () {
      this.filter = null
      this.search = null
    },
    clickNotifyLeave () {
      if (this.notifyModalData.notify_leave) {
        this.notifyModalData.notify_leave_action = 0
        this.notifyModalData.notify_leave_timeout = 0
      } else {
        this.notifyModalData.notify_leave_action = 1
        this.notifyModalData.notify_leave_timeout = 1
      }
    },
    getNotify () {
      const vm = this
      notifyTemplate.get('', vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          const data = res.data.data
          let hasSettingID = false
          vm.notifySetting = []
          if (data.length) {
            data.forEach(item => {
              if (item.name) {
                vm.notifySetting.push(item)
                if (vm.notifySettingTypeID === item.id) {
                  hasSettingID = true
                }
              }
            })
            vm.notifySettingTypeID = hasSettingID
              ? vm.notifySettingTypeID
              : vm.notifySetting[0].id
          }
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    getNotifySetting () {
      const vm = this
      if (vm.notifySettingType === 'template') {
        vm.notifySetting.forEach(item => {
          if (item.id === vm.notifySettingTypeID) vm.notifyModalData = item
        })
      }
    },
    showNotifyModal (id, device = null) {
      const vm = this
      async function getNotifyDate () {
        await vm.getNotify()
        vm.Loading()
        vm.showNotifyResidentID = id
        vm.getLINEAuth()
        notify.get(id, vm.token).then(res => {
          if (res.status <= 201 && res.data.status === 'success') {
            const data = res.data.data.notify_setting
            if (data) {
              vm.hasnotifyData = true
              if (data.is_shared || data.is_template) {
                vm.notifySettingType = 'template'
                vm.notifySettingTypeID = data.id
                vm.getNotifySetting()
              } else {
                vm.notifySettingType = 'custom'
                vm.notifyModalData = data
                if (device && device.chiline_user !== null) {
                  vm.notifyModalData.isChilineUser = true
                }

                if (device && device.heart_rate_enabled === true) {
                  vm.notifyModalData.heartRateEnabled = true
                }
              }
            } else {
              vm.hasnotifyData = false
              vm.notifySettingType = 'template'
              vm.getNotifySetting()
            }
            vm.modalTitle.id = res.data.data.id
            vm.modalTitle.name = res.data.data.name
            vm.$nextTick(() => {
              vm.bootstrap_modal = new Modal(vm.$refs.notifyModal)
              vm.bootstrap_modal.show()
              vm.initBootstrapTab()
              vm.initBootstrapTooltip()
            })
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errors.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
      }
      getNotifyDate()
    },
    submitNotifyModal () {
      const vm = this
      if (vm.notifySettingType === 'template') {
        console.log('template')
        notify
          .useSharedNotify(
            vm.showNotifyResidentID,
            {
              id: vm.notifySettingTypeID
            },
            vm.token
          )
          .then(res => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getResidents(vm.showNotifyResidentID)
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__editSuccess')
              })
              vm.Alerted()
              vm.bootstrap_modal.hide()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
              vm.Alerted()
              vm.validate = res.data.validate
            }
            vm.Loaded()
          })
      } else {
        delete vm.notifyModalData.agency_id
        delete vm.notifyModalData.created_at
        delete vm.notifyModalData.is_shared
        delete vm.notifyModalData.is_template
        delete vm.notifyModalData.name
        delete vm.notifyModalData.updated_at
        if (vm.notifyModalData.notify_restless_duration < 60) {
          vm.notifyModalData.notify_restless_duration = 60
        }
        notify
          .useCustomNotify(vm.showNotifyResidentID, vm.notifyModalData, vm.token)
          .then(res => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getResidents(vm.showNotifyResidentID)
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__editSuccess')
              })
              vm.Alerted()
              vm.bootstrap_modal.hide()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
              vm.Alerted()
              vm.validate = res.data.validate
            }
            vm.Loaded()
          })
      }
    },
    copyNotifyData () {
      const vm = this
      vm.notifySetting.forEach(item => {
        if (item.id === vm.notifySettingTypeID) {
          vm.notifyModalData = item
          delete vm.notifyModalData.id
        }
      })
      vm.notifySettingType = 'custom'
    },
    changeSliderNotifyRespiration (vale) {
      this.notifyModalData.notify_respiration_min = vale[0]
      this.notifyModalData.notify_respiration_max = vale[1]
    },
    changeSliderNotifyHeartRate (vale) {
      this.notifyModalData.notify_heartRate_min = vale[0]
      this.notifyModalData.notify_heartRate_max = vale[1]
    },
    changeSliderNotifyTemp (vale) {
      this.notifyModalData.notify_temp_min = vale[0]
      this.notifyModalData.notify_temp_max = vale[1]
    },
    addExcludeTime () {
      this.notifyModalData.exclude_range.push({
        started_at: null,
        ended_at: null
      })
    },
    delExcludeTime (index) {
      this.notifyModalData.exclude_range.splice(index, 1)
    },
    minutesToSeconds (time) {
      return time * 60
    },
    secondsToMinutes (time) {
      return time / 60
    },
    toEditThisTemplate (id) {
      const routeUrl = this.$router.resolve({
        name: 'NotifyTemplate',
        params: { id: id }
      })
      window.open(routeUrl.href, '_blank')
    },
    addModal () {
      this.is_edit = false
      this.editResidentForm.name = null
      this.editResidentForm.birthday = ''
      this.editResidentForm.gender = null
      this.editResidentForm.id_number = null
      this.editResidentForm.note = null
      this.$nextTick(function () {
        this.bootstrap_modal = new Modal(this.$refs.modal)
        this.bootstrap_modal.show()
      })
    },
    editModal (id) {
      this.is_edit = true
      this.edit_id = id
      this.items.forEach((ele) => {
        if (ele.id === id) {
          this.editResidentForm.name = ele.name
          this.editResidentForm.age = ele.age
          this.editResidentForm.birthday = ele.birthday
          this.editResidentForm.gender = ele.gender
          this.editResidentForm.id_number = ele.id_number
          this.editResidentForm.note = ele.note
        }
      })
      this.$nextTick(function () {
        this.bootstrap_modal = new Modal(this.$refs.modal)
        this.bootstrap_modal.show()
      })
    },
    submitResidentForm () {
      const vm = this
      if (vm.is_edit) {
        resident.update(vm.edit_id, this.editResidentForm, vm.token).then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getResidents()
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__editSuccess')
            })
            vm.Alerted()
            vm.bootstrap_modal.hide()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
      } else {
        resident.create(this.editResidentForm, vm.token).then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getResidents()
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__addSuccess')
            })
            vm.Alerted()
            vm.bootstrap_modal.hide()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
            vm.validate = res.data.validate
          }
          vm.Loaded()
        })
      }
    },
    deleteAlert (id) {
      const vm = this
      vm.edit_id = id
      vm.items.forEach((ele) => {
        if (ele.id === id) {
          this.editResidentForm.name = ele.name
        }
      })
      vm.bootstrap_modal = new Modal(vm.$refs.deleteModal)
      vm.bootstrap_modal.show()
    },
    deleteResident (id) {
      const vm = this
      vm.bootstrap_modal.hide()
      resident.delete(id, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.getResidents()
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__deleteSuccess')
          })
          vm.Alerted()
          vm.bootstrap_modal.hide()
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    initBootstrapTab () {
      const vm = this
      if (vm.notifySettingType === 'template' && vm.$refs.templateList) {
        vm.bootstrap_template_tab = []
        const triggerTabList = [].slice.call(
          vm.$refs.templateList.querySelectorAll('button')
        )
        triggerTabList.forEach(function (triggerEl, index) {
          vm.bootstrap_template_tab.push(new Tab(triggerEl))
          triggerEl.addEventListener('click', function (event) {
            event.preventDefault()
            vm.bootstrap_template_tab[index].show()
          })
        })
        vm.bootstrap_template_tab[0].show()
      } else if (vm.notifySettingType === 'custom' && vm.$refs.customList) {
        vm.bootstrap_custom_tab = []
        const triggerTabList = [].slice.call(
          vm.$refs.customList.querySelectorAll('button')
        )
        triggerTabList.forEach(function (triggerEl, index) {
          vm.bootstrap_custom_tab.push(new Tab(triggerEl))
          triggerEl.addEventListener('click', function (event) {
            event.preventDefault()
            vm.bootstrap_custom_tab[index].show()
          })
        })
        vm.bootstrap_custom_tab[0].show()
      }
    },
    initBootstrapTooltip () {
      const vm = this
      vm.$nextTick(function () {
        const delay = { show: 200, hide: 200 }
        vm.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl, {
              trigger: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 'manual' : 'hover',
              delay: delay
            })
          })
      })
    },
    getLINEAuth () {
      const vm = this
      line.lineAuth(vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.LINEAuth = res.data.data
        } else {
          vm.Loaded()
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    }
  },
  created () {},
  mounted () {
    if (this.userInfo) {
      this.getResidents()
      this.getNotify()
    }
  }
}
</script>

<style lang="scss">
#notify {
  .modal-content {
    background-color: #f3f3f3;
  }
}
.custom-tab-check {
  .form-check {
    position: relative;
    width: 105px;
    height: 34px;
    margin-bottom: 20px;
    &-input {
      opacity: 0;
      &:checked + .form-check-label {
        background-color: $primary;
        color: #fff;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 90%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 5px 0 5px;
          border-color: $primary transparent transparent transparent;
        }
      }
    }
    &-label {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding: 5px 0;
      text-align: center;
      background-color: #fff;
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      .form-check-label {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}

.slider-connect {
  background: $primary;
}
.slider-tooltip {
  border: 1px solid $dark;
  background: $dark;
}

.resident .icon {
  width: 20px;
}
</style>
